<style lang="sass" scoped>

</style>
<template lang="pug">
.bg-white.shadow-sm.p-4.mt-4.ml-0(style='max-width: 780px')
  h4.border-bottom.py-4.pl-2(style='border-top: solid 1px #333') 내보내기
  .border-bottom.p-4
    strong.pb-2.d-block 권한없음
    p 실험실 기능을 사용하려면 문의를 보내주세요.


</template>

<script>

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
  },
  mounted() {

  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
